<template>
    <div class="battery-management-container">
        <div class="table-tool">
            <TableTool 
                ref="tableTool" 
                :page="page"
                :pageSize="pageSize"
                @dataChange="dataChangeHandel" 
                :multipleCodes="multipleCodes"
                :multipleOrgIds="multipleOrgIds"
            />
        </div>
        <div class="table-container">
            <div class="table-data-info">
                <img class="tips-icon" :src="require('@/assets/img/volume.png')"/>
                <span>设备总量</span><span style="margin: 0 10px; color: #FF0000;">{{total}}</span>
                <span>本机构设备总量</span><span style="margin: 0 10px; color: #FF0000;">{{total}}</span>
                <span>已处置设备总量</span><span style="margin: 0 10px; color: #FF0000;">{{total}}</span>
                <span>本机构已处置设备总量</span><span style="margin: 0 10px; color: #FF0000;">{{total}}</span>
            </div>
            <div class="table-content">
                <el-table 
                    style="width: 100%"
                    height="100%"
                    :data="list"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="55"
                    >
                    </el-table-column>
                    <el-table-column
                        label="电池ID"
                        prop="batteryCode"
                    >
                    </el-table-column>
                    <el-table-column
                        label="电池类别"
                        prop="batteryType"
                    >
                    </el-table-column>
                    <el-table-column
                        label="品牌"
                        prop="batteryBrand"
                    >
                    </el-table-column>
                    <el-table-column
                        label="型号"
                        prop="batteryModel"
                    >
                    </el-table-column>
                    <el-table-column
                        label="归属机构"
                        prop="orgName"
                    >
                    </el-table-column>
                    <el-table-column
                        label="归属站点"
                        prop="siteName"
                    >
                    </el-table-column>
                    <el-table-column
                        label="调入时间"
                        width="180px"
                        prop="transferInTime"
                    >
                    </el-table-column>
                    <el-table-column
                        label="故障次数"
                        prop="faultCount"
                    >
                    </el-table-column>
                    <el-table-column
                        label="维修次数"
                        prop="repairCount"
                    >
                    </el-table-column>
                    <el-table-column
                        label="保养次数"
                        prop="maintenanceCount"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="200"
                    >
                        <template slot-scope="{row}">
                            <DetailModal :batteryCode="row.batteryCode">
                                <a class="operate-a">查看</a>
                            </DetailModal>
                            <TransferModal :batteryCodes="[row.batteryCode]" :getData="getTableData">
                                <a class="operate-a">调拨</a>
                            </TransferModal>
                            <TransferSiteModal 
                                :orgId="row.orgId" 
                                :batteryCodes="[row.batteryCode]" 
                                :getData="getTableData"
                            >
                                <a class="operate-a" v-if="!row.siteId">绑定</a>
                            </TransferSiteModal>    
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-page">
                <div class="table-page-container">
                    <el-pagination
                        background
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableTool from './components/TableTool';
import DetailModal from './components/DetailModal';
import TransferModal from './components/TransferModal';
import TransferSiteModal from './components/TransferSiteModal'
import './Index.less';
export default {
    name: 'BatteryManagement',
    data() {
        return {
            page: 1,
            pageSize: 10,
            list: [],
            total: 0,
            multipleCodes: [],
            multipleOrgIds: []
        }
    },
    components: {
        TableTool,
        DetailModal,
        TransferModal,
        TransferSiteModal
    },
    methods: {
        dataChangeHandel(value) {
            const { records, total, page } = value;
            this.list = records;
            this.total = total;
            this.page = page;
        },
        handleSelectionChange(val) {
            this.multipleCodes = val.map(item => item.batteryCode);
            this.multipleOrgIds = val.map(item => item.orgId);
        },
        getTableData() {
            this.$refs.tableTool.getData();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.$nextTick(() => {
                this.getTableData();
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            this.$nextTick(() => {
                this.getTableData();
            })
        },
    }
}
</script>