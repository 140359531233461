<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="battery-detail-modal"
                title="电池调拨"
                :visible.sync="dialogVisible"
                width="600px"
            >
                <el-form label-width="150px" :model="formData" :rules="rules" ref="batteryForm">
                    <el-form-item label="已选电池：">
                        <div class="battery-info">
                            <span class="battery-code" v-for="item in batteryCodes" :key="item">{{item}}</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="机构：" prop="orgId">
                        <el-tree-select 
                            v-model="formData.orgId"
                            ref="group-select-tree"
                            class="group-select-tree" 
                            :selectParams="selectParams" 
                            :treeParams="treeParams" 
                            style="width: 220px;"
                        >
                        </el-tree-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import axios from '@/utils/axios';
import './TransferModal.less';
export default {
    name: 'TransferModal',
    props: {
        batteryCodes: {
            type: Array,
            default: () => []
        },
        getData: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            dialogVisible: false,
            formData: {
                orgId: ''
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgCode'
                }
            },
            rules: {
                orgId: [
                    { required: true, message: '请选择机构', trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        clickHandel() {
            if (this.batteryCodes.length === 0) {
                this.$message.info('请选择电池');
                return;
            }
            this.dialogVisible = true;
            this.getGroupTreeData();
        },
        async getGroupTreeData() {
            const rs = await axios.get('/api/userInfo/getCurrLoginUserInfo');
            const { orgId } = rs.org;
            const groupTreeData = await axios.get(`/api/org/getOrgTree/${orgId}`);
            this.treeParams.data = [groupTreeData];
            this.$refs['group-select-tree'].treeDataUpdateFun([groupTreeData]);
        },
        submitHandel() {
            this.$refs['batteryForm'].validate(valid => {
                if (valid) {
                    axios.post('/api/battery/transfer', {
                        batteryCodes: this.batteryCodes,
                        orgCode: this.formData.orgId
                    }).then(rs => {
                        this.$message.success('调拨成功');
                        this.dialogVisible = false;
                        this.getData();
                        this.formData.orgId = '';
                    }).catch(err => {
                        this.$message.error(err);
                    });
                }
            });
        }
    }
}
</script>