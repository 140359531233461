<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="battery-detail-modal"
                title="电池绑定"
                :visible.sync="dialogVisible"
                width="600px"
            >
                <el-form label-width="150px" :model="formData" :rules="rules" ref="batteryForm">
                    <el-form-item label="已选电池：">
                        <div class="battery-info">
                            <span class="battery-code" v-for="item in batteryCodes" :key="item">{{item}}</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="站点：" prop="siteId">
                        <el-select v-model="formData.siteId" placeholder="选择站点">
                            <el-option v-for="item in siteList" :key="item.key" :value="item.key" :label="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import axios from '@/utils/axios';
import './TransferModal.less';
export default {
    name: 'TransferModal',
    props: {
        orgId: {
            type: Number,
            default: 0
        },
        batteryCodes: {
            type: Array,
            default: () => []
        },
        multipleOrgIds: {
            type: Array,
            default: () => []
        },
        getData: {
            type: Function,
            default: () => {}
        },
        type: {
            type: String,
            default: 'single'
        }
    },
    data() {
        return {
            dialogVisible: false,
            formData: {
                siteId: ''
            },
            rules: {
                siteId: [
                    { required: true, message: '请选择电池', trigger: 'blur' },
                ]
            },
            siteList: []
        }
    },
    methods: {
        clickHandel() {
            if (this.batteryCodes.length === 0) {
                this.$message.info('请选择电池');
                return;
            }
            if (this.type !== 'single') {
                const flag =  this.multipleOrgIds.every(el => el === this.multipleOrgIds[0]);
                if (!flag) {
                    this.$message.info('只支持批量调拨相同机构下的电池');
                    return;
                }
            }
            this.dialogVisible = true;
            this.getSiteList();
        },
        getSiteList() {
            axios.get(`/api/site/getSiteSelectList/${this.orgId}`).then(rs => {
                this.siteList = rs;
            }).catch(err => {
                this.$message.error(err);
            }); 
        },
        submitHandel() {
            this.$refs['batteryForm'].validate(valid => {
                if (valid) {
                    axios.post('/api/battery/bind', {
                        batteryCodes: this.batteryCodes,
                        siteId: Number(this.formData.siteId)
                    }).then(rs => {
                        this.$message.success('绑定站点成功');
                        this.dialogVisible = false;
                        this.getData();
                        this.formData.siteId = '';
                    }).catch(err => {
                        this.$message.error(err);
                    });
                }
            });
        }
    }
}
</script>