<template>
    <div class="table-tool-container">
        <div class="table-search-tool-container">
            <el-form class="battery-form" :inline="true" :model="formData">
                <el-form-item label="电池ID">
                    <el-input placeholder="电池ID" size="small" v-model="formData.batteryCode"></el-input>
                </el-form-item>
                <el-form-item label="归属">
                    <el-tree-select 
                        v-model="formData.orgId"
                        ref="group-select-tree"
                        class="group-select-tree" 
                        :selectParams="selectParams" 
                        :treeParams="treeParams" 
                        style="width: 200px;"
                    >
                    </el-tree-select>
                </el-form-item>
                <el-form-item label="电池类别">
                    <el-select :value="''" placeholder="电池类别" size="small" class="area-form-item" v-model="formData.batteryType">
                        <el-option label="全部" :value="''"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="品牌" class="brand-form-item">
                    <el-input placeholder="品牌" size="small" v-model="formData.batteryBranch"></el-input>
                </el-form-item>
                <el-form-item label="调入时间" class="times-form-item">
                    <el-date-picker
                        v-model="formData.times"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="是否维修" class="repaire-form-item">
                    <el-select :value="''" placeholder="是否维修" size="small" v-model="formData.repair">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="是" :value="true"></el-option>
                        <el-option label="否" :value="false"></el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item style="float: right; marginRight: 0px;">
                    <el-button type="primary" @click="getData(1)">搜索</el-button>
                    <el-button @click="resetHandel">重置</el-button>
                </el-form-item> -->
            </el-form>
        </div>
        <div class="table-operate-tool-container">
            <!-- <el-button type="primary" style="marginRight: 15px;">导出</el-button> -->
            <TransferModal :batteryCodes="multipleCodes" :getData="getData">
                <el-button type="primary" style="marginRight: 15px;">批量调拨</el-button>
            </TransferModal>
            <TransferSiteModal 
                :multipleOrgIds="multipleOrgIds" 
                :batteryCodes="multipleCodes" 
                :getData="getData"
                :orgId="multipleOrgIds[0]"
                type="mutiple"
            >
                <el-button type="primary" style="marginRight: 15px;">批量绑定</el-button>
            </TransferSiteModal>
            <el-button type="primary" @click="unbindBatch">批量解绑</el-button>
            <span style="float: right;">
                <el-button type="primary" @click="getData(1)">搜索</el-button>
                <el-button @click="resetHandel">重置</el-button>
            </span>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axios';
import './TableTool.less';
import TransferModal from './TransferModal';
import TransferSiteModal from './TransferSiteModal'
import moment from 'moment';

export default {
    name: 'TableTool',
    props: {
        page: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        },
        multipleCodes: {
            type: Array,
            default: () => []
        },
        multipleOrgIds: {
            type: Array,
            default: () => []
        }
    },
    components: {
        TransferModal,
        TransferSiteModal
    },
    data() {
        return {
            formData: {
                batteryCode: '',
                batteryType: '',
                orgId: '',
                repair: '',
                batteryBranch: '',
                times: ''
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgCode'
                }
            }
        }
    },
    methods: {
        getData(_page) {
            let {
                page,
                pageSize
            } = this;
            const {
                batteryCode,
                batteryType,
                orgId,
                repair,
                batteryBranch,
                times
            } = this.formData;
            let beginDay  = '';
            let endDay = '';
            if (times) {
                const [startTime, endTime] = times;
                beginDay = moment(startTime).format('YYYY-MM-DD');
                endDay = moment(endTime).format('YYYY-MM-DD');
            }
            axios.get(`/api/battery/pageList/${_page ? _page : page}/${pageSize}`, {
                batteryCode,
                batteryType,
                orgCode: orgId,
                repair,
                batteryBranch,
                beginDay,
                endDay
            }).then(rs => {
                this.$emit('dataChange', rs);
            }).catch(err => {
                this.$message.error(err);
            });
        },
        resetHandel() {
            this.formData = {
                batteryCode: '',
                batteryType: '',
                orgId: '',
                repair: '',
                batteryBranch: ''
            };
        },
        async getOrgData() {
            const userinfo = localStorage.getItem('currentUser')
            const orgId = JSON.parse(userinfo).orgId;
            const rs = await axios.get(`/api/org/getOrgTree/${Number(orgId)}`);
            this.treeParams.data = [rs]
            this.$nextTick(() => {
                this.$refs['group-select-tree'].treeDataUpdateFun(this.treeParams.data);
            });
        },
        unbindBatch() {
            if (this.multipleCodes.length === 0) {
                this.$message.info('请选择电池');
                return;
            }
            this.$confirm('是否解绑所选电池?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post('/api/battery/unbind', {
                    batteryCodes: this.multipleCodes
                }).then(() => {
                    this.$message.success('解绑成功');
                    this.getData();
                }).catch(err => {
                    this.$message.error(err);
                });
            }).catch(() => {      
            });
        }
    },
    mounted() {
        this.getOrgData();
        this.getData();
    }
}
</script>