<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="battery-detail-modal"
                title="电池详情"
                :visible.sync="dialogVisible"
                width="1500px"
            >
                <div class="battery-modal-detail-content">
                    <div :id="`battery_${batteryCode}`" class="top-map-container">
                    </div>
                    <div class="bottom-person-info-container">
                        <div class="left-info">
                            <div class="title">
                                基本信息
                            </div>
                            <div class="info-detail-container">
                                <div
                                    class="info-item"
                                    v-for="(item, index) in detailInfo"
                                    :key="index"
                                >
                                    <div class="info-name">{{item.name}}：</div>
                                    <div class="info-value">{{item.value}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="center-info">
                            <div class="title">
                                状态信息
                            </div>
                            <div class="info-detail-container">
                                <div
                                    class="info-item"
                                    v-for="(item, index) in statusInfo"
                                    :key="index"
                                >
                                    <div class="info-name">{{item.name}}：</div>
                                    <div class="info-value">{{item.value}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="right-info">
                            <div class="title">
                                运维信息
                            </div>
                            <div class="right-info-detail-container">
                                <div class="work-info-container">
                                    <div class="work-total">故障次数：{{ jobStatistics.jobCount }}</div>
                                    <div class="work-maintence">维修次数：{{ jobStatistics.rescueJobCount }}</div>
                                    <div class="work-xunjian">检修次数：{{ jobStatistics.inspectionJobCount }}</div>
                                </div>
                                <div class="work-detail-container">
                                    <div class="work-detail-item" v-for="(item, index) in workList" :key="index">
                                        <div class="work-info-item" style="width: 100%">
                                            <span>工单编号：</span>
                                            <span>{{item.gdbh}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>故障名称：</span>
                                            <span>{{item.gzmc}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>故障信息：</span>
                                            <span>{{item.gzxx}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>运维人员：</span>
                                            <span>{{item.ywry}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>操作时间：</span>
                                            <span>{{item.jdsj}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>处理方式：</span>
                                            <span>{{item.clfs}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>处理结果：</span>
                                            <span>{{item.cljg}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import L from "leaflet/dist/leaflet";
import "leaflet.chinatmsproviders";
import "leaflet/dist/leaflet.css";
import './DetailModal.less';
import axios from '@/utils/axios';
import iconImg from '@/assets/img/main-person-icon.png';

const icon = L.icon({
    iconUrl: iconImg
});
export default {
    name: 'DetailModal',
    props: {
        batteryCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: false,
            map: null,
            detailInfo: [
                { name: '电池ID', value: '' },
                { name: '电池类型', value: '' },
                { name: '电池型号', value: '' },
                { name: '容量', value: '' },
                { name: '厂家', value: '' },
                { name: '品牌', value: '' },
                { name: '调入时间', value: '' },
                { name: '归属机构', value: '' },
            ],
            statusInfo: [
                { name: '电池状态', value: '' },
                { name: '电量状态（SOC）', value: '' },
                { name: '电流', value: '' },
                { name: '温度', value: '' },
                { name: '健康状态（SOH）', value: '' },
                { name: '累计里程', value: '' },
                { name: '累计放电时长', value: '' },
                { name: '循环次数', value: '' },
            ],
            workList: [],
            point: null,
            jobStatistics: {
                inspectionJobCount: 0,
                jobCount: 0,
                rescueJobCount: 0
            }
        }
    },
    methods: {
        clickHandel() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.initMap()
                setTimeout(() => {
                    this.getBatteryDetail();
                    this.getBatteryLngLat(this.batteryCode);
                    this.getJobList001(this.batteryCode)
                }, 500);
            });
        },
        initMap() {
            if (this.map) {
                return;
            }
            const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", {
                maxZoom: 18,
                minZoom: 1,
            });
            const normal = L.layerGroup([normalm]);
            const map = L.map(`battery_${this.batteryCode}`, {
                center: [29.57754, 106.586177],
                zoom: 10,
                layers: [normal],
                zoomControl: false,
            });
            this.map = map;
        },
        addIcon(lat, lng) {
            if (this.map) {
                this.map.panTo([lat, lng]);
                if (this.point) {
                    this.point.remove();
                }
                this.point = L.marker([lat, lng], {
                    icon
                });
                this.point.addTo(this.map);
            }
        },
        getBatteryDetail() {
            axios.get(`/api/battery/detail/${this.batteryCode}`).then(rs => {
                const {
                    batterStatus,
                    batteryBrand,
                    batteryCode,
                    batteryModel,
                    batteryType,
                    capacity,
                    current,
                    cycleTimes,
                    faultCount,
                    id,
                    maintenanceCount,
                    mileage,
                    orgCode,
                    orgId,
                    orgName,
                    ratedVoltage,
                    repairCount,
                    siteId,
                    siteName,
                    soc,
                    soh,
                    temperature,
                    transferInTime,
                    upTime,
                    voltage,
                    jobStatistics
                } = rs;
                this.jobStatistics = jobStatistics;
                this.detailInfo = [
                    { name: '电池ID', value: batteryCode },
                    { name: '电池类型', value: batteryType },
                    { name: '电池型号', value: batteryModel },
                    { name: '容量', value: capacity },
                    { name: '厂家', value: '未知' },
                    { name: '品牌', value: batteryBrand },
                    { name: '调入时间', value: transferInTime },
                    { name: '归属机构', value: orgName }
                ];
                this.statusInfo = [
                    { name: '电池状态', value:  batterStatus },
                    { name: '电量状态（SOC）', value:  soc },
                    { name: '电流', value:  current },
                    { name: '温度', value: temperature },
                    { name: '健康状态（SOH）', value: soh },
                    { name: '累计里程', value: mileage },
                    { name: '累计放电时长', value: '未知' },
                    { name: '循环次数', value: cycleTimes }
                ];
            }).catch(err => {
               this.$message.error(err); 
            });
        },
        getBatteryLngLat(batteryCode) {
            axios.get(`/api/battery/getBatteryLngLat/${batteryCode}`).then(rs => {
                const { latitude, longitude } = rs;
                if (latitude && longitude) {
                    this.addIcon(latitude, longitude);
                }
            }).catch(err => {
                this.$message.error(err);
            });
        },  
        getJobList001(batteryCode) {
            axios.post('/api/rescueJob/getJobList001', { batteryCode }).then(rs => {
                this.workList = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        }
    }
}
</script>